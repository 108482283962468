import { UrlSegment, Route, UrlMatchResult } from '@angular/router';

export function customMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  if (segments.length >= 3 && segments[0].path === 'Engine') {
    const manualType = segments[1].path;
    const engine = segments[2].path;

    return {
      consumed: segments, // Consumes all segments
      posParams: {
        manualType: new UrlSegment(manualType, {}),
        engine: new UrlSegment(engine, {}),
      },
    };
  }
  return null;
}
