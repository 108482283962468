import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { PingAuthenticationModule } from '@techops-ui/ping-authentication';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { HomeComponent } from './home/home.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { UrlSerializerService } from './custom-url-handling-strategy';
import { AirframeComponent } from './airframe/airframe.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { EngineComponent } from './engine/engine.component';
import { customMatcher } from './engine/customMatcher';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Airframe/:manualType/:fleet/:noseNumber',
    component: AirframeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Airframe/:manualType/:fleet',
    component: AirframeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Procedure/:manual',
    component: ProcedureComponent,
    canActivate: [PingAuthGuard],
  },
  {
    matcher: customMatcher,
    component: EngineComponent,
    canActivate: [PingAuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        AirframeComponent,
        ProcedureComponent,
        EngineComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        PingAuthenticationModule.forRoot({
            authority: environment.pingAuthorityUrl,
            client_id: environment.pingClientID,
        }),
        RouterModule.forRoot(routes, { useHash: false })], providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: UrlSerializer, useClass: UrlSerializerService },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
